// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-lines */
import { InternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { API_TYPES } from "@src/appV2/api/constants";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { isCbhApiAxiosError } from "@src/appV2/Errors/errorUtilts";
import { useToast } from "@src/appV2/lib";
import { useOnboardingRequirements } from "@src/appV2/redesign/PlacementCandidate/hooks/useOnboardingRequirements";
import { type PlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/types";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type QueryObserverResult } from "@tanstack/react-query";
import { isAfter, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH } from "../../PlacementCandidate/PlacementCandidateModalRoutes";
import { useCreateInterview } from "../api/useCreateInterview";
import { useCreatePlacementApplication } from "../api/useCreatePlacementApplication";
import {
  transformInterviewAvailabilityData,
  useGetInterviewAvailability,
} from "../api/useGetInterviewAvailability";
import { type PlacementDetail } from "../api/useGetPlacement";
import { type PlacementResponse } from "../types/fetchPlacements.schema";
import { useIsJobInterviewsEnabled } from "../useIsJobInterviewsEnabled";
import { useIsJobsEnabledForOnboardingCandidates } from "../useIsJobsEnabledForOnboardingCandidates";
import {
  getPlacementActionProps,
  hasAppliedToPlacement,
  hasBookedInterview,
  isApplicationCompleted,
} from "../utils";
import { InterviewBookedDialog } from "./InterviewBookedDialog";
import { InterviewCancelBottomSheet } from "./InterviewCancelBottomSheet";
import { InterviewCancelButton } from "./InterviewCancelButton";
import { type InterviewTime } from "./InterviewTimeSelector";
import { PlacementApplicationSuccessBottomSheet } from "./PlacementApplicationSuccessBottomSheet";
import { PlacementDetailBookInterviewDialog } from "./PlacementDetailBookInterviewDialog";
import {
  PLACEMENT_ACTION_TYPE,
  type PlacementActionType,
  PlacementPrimaryAction,
} from "./PlacementPrimaryAction";
import { WalkInNoteDialog } from "./WalkInNoteDialog";

interface PlacementApplyButtonContainerProps {
  placementDetails: PlacementDetail;
  placementCandidate: PlacementCandidate;
  refetchPlacement: () => Promise<QueryObserverResult<PlacementResponse>>;
}

export function PlacementApplyButtonContainer(props: PlacementApplyButtonContainerProps) {
  const { placementDetails, placementCandidate, refetchPlacement } = props;
  const { tmz: workerTimezone, userId: workerId } = useDefinedWorker();
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();
  const isJobsEnabledForOnboardingCandidates = useIsJobsEnabledForOnboardingCandidates();
  const {
    data: interviewAvailabilityResponse,
    isInitialLoading: isLoadingInterviewAvailability,
    refetch: refetchInterviewAvailability,
  } = useGetInterviewAvailability(
    { workplaceId: placementDetails.workplace.id },
    {
      enabled:
        isDefined(placementDetails.workplace.id) &&
        placementDetails.interviewSettings?.attributes?.scheduleInterviews &&
        isJobInterviewsEnabled,
    },
    {
      filter: { workerId },
    }
  );

  const interviewAvailability = transformInterviewAvailabilityData(
    interviewAvailabilityResponse,
    workerTimezone,
    placementDetails
  );
  const { showErrorToast } = useToast();
  const history = useHistory();
  const worker = useDefinedWorker();

  const [selectedInterviewTime, setSelectedInterviewTime] = useState<InterviewTime | undefined>();
  const placementApplicationSuccessModalState = useModalState();
  const bookInterviewModalState = useModalState();
  const walkInNoteModalState = useModalState();
  const [bookInterviewModalType, setBookInterviewModalType] = useState<PlacementActionType>(
    PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW
  );
  const interviewBookedModalState = useModalState();
  const cancelInterviewModalState = useModalState();
  const {
    isLoading: isOnboardingRequirementsLoading,
    isPending: isProfilePending,
    isCompleted: isProfileCompleted,
    requirements,
  } = useOnboardingRequirements(worker.userId);

  const { mutateAsync: createPlacementApplication, isLoading: isCreatingPlacementApplication } =
    useCreatePlacementApplication();
  const { mutateAsync: createInterview, isLoading: isCreatingInterview } = useCreateInterview();

  const interview = placementDetails.interviews.find(
    (interview) => interview.attributes.status === InterviewStatus.BOOKED
  );
  const hasApplied = hasAppliedToPlacement(placementDetails);
  const bookedInterview = hasBookedInterview(placementDetails);
  const applicationCompleted = isApplicationCompleted(placementDetails);

  async function handleApply() {
    try {
      await createPlacementApplication({
        placementCandidateId: placementCandidate.id,
        placementDetails,
      });
      await refetchPlacement();
      placementApplicationSuccessModalState.openModal();
    } catch (error) {
      if (
        isCbhApiAxiosError(error) &&
        error.response.data.errors.some(
          (error) => error.detail === "You need to have both licenses and ID verification approved"
        )
      ) {
        showErrorToast(
          <Text variant="body2" color="inherit">
            Application failed since you need to both licenses and ID verification approved
            <InternalLink
              color="inherit"
              sx={{ textDecorationColor: "inherit" }}
              to={PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH}
            >
              Tap here
            </InternalLink>{" "}
            to complete your profile.
          </Text>
        );
        return;
      }

      if (
        isCbhApiAxiosError(error) &&
        error.response.data.errors.some(
          (error) =>
            error.detail ===
            "The candidate is not licensed for any of this placement's worker types in the facility's state"
        )
      ) {
        showErrorToast(
          <Text variant="body2" color="inherit">
            Application failed since you aren&apos;t licensed to work in the facility&apos;s state.{" "}
            <InternalLink
              color="inherit"
              sx={{ textDecorationColor: "inherit" }}
              to={DeprecatedGlobalAppV1Paths.LICENSE_MANAGER}
            >
              Tap here
            </InternalLink>{" "}
            to update your licenses.
          </Text>
        );
        return;
      }

      showErrorToast("Failed to apply for job.");
    }
  }

  async function handleBookInterview(time: { start: Date; end: Date }): Promise<boolean> {
    try {
      if (hasApplied) {
        const placementApplication = placementDetails?.applications?.at(-1);

        if (!placementApplication) {
          throw new Error("No placement application found");
        }

        await createInterview({
          relatedResource: {
            type: API_TYPES.placementApplication,
            id: placementApplication.id,
          },
          start: zonedTimeToUtc(time.start, workerTimezone),
          end: zonedTimeToUtc(time.end, workerTimezone),
        });
        return true;
      }

      await createPlacementApplication({
        placementCandidateId: placementCandidate.id,
        placementDetails,
        scheduleInterview: {
          start: zonedTimeToUtc(time.start, workerTimezone).toISOString(),
          end: zonedTimeToUtc(time.end, workerTimezone).toISOString(),
        },
      });

      return true;
    } catch (error) {
      if (isCbhApiAxiosError(error)) {
        showErrorToast(
          <Text variant="body2" color="inherit">
            {error.response.data.errors.map((error) => error.detail).join(", ")}
          </Text>
        );
        return false;
      }

      showErrorToast("Failed to book interview.");
      return false;
    }
  }

  async function handleRescheduleInterview(time: { start: Date; end: Date }): Promise<boolean> {
    try {
      const rescheduledInterview = placementDetails.interviews.find(
        (interview) =>
          interview.attributes.status === InterviewStatus.BOOKED &&
          isAfter(parseISO(interview.attributes.start), new Date())
      );

      if (!rescheduledInterview) {
        throw new Error(
          "Could not find an interview to reschedule based on the status and start date"
        );
      }

      await createInterview({
        relatedResource: {
          type: API_TYPES.interview,
          id: rescheduledInterview.id,
        },
        start: zonedTimeToUtc(time.start, workerTimezone),
        end: zonedTimeToUtc(time.end, workerTimezone),
      });
      return true;
    } catch (error) {
      if (isCbhApiAxiosError(error)) {
        showErrorToast(
          <Text variant="body2" color="inherit">
            {error.response.data.errors.map((error) => error.detail).join(", ")}
          </Text>
        );
        return false;
      }

      showErrorToast("Failed to reschedule interview.");
      return false;
    }
  }

  const primaryActionProps = getPlacementActionProps({
    placementDetails,
    isLoading:
      isCreatingPlacementApplication ||
      isLoadingInterviewAvailability ||
      isCreatingInterview ||
      isOnboardingRequirementsLoading,
    isJobInterviewsEnabled,
    isJobsEnabledForOnboardingCandidates,
    isProfilePending,
    isProfileCompleted,
    onApply: handleApply,
    onCompleteYourProfile: () => {
      history.push(PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH);
    },
    onOpenBookInterview: () => {
      setBookInterviewModalType(PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW);
      if (placementDetails.workplace.placementSettings?.isProspect) {
        walkInNoteModalState.openModal();
      } else {
        bookInterviewModalState.openModal();
      }
    },
    onOpenRescheduleInterview: () => {
      setBookInterviewModalType(PLACEMENT_ACTION_TYPE.RESCHEDULE_INTERVIEW);
      bookInterviewModalState.openModal();
    },
    onUploadLicense: () => {
      history.push(DeprecatedGlobalAppV1Paths.LICENSE_MANAGER);
    },
  });

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: 4,
          paddingY: 6,
          backgroundColor: (theme) => theme.palette.background.tertiary,
          borderTop: 1,
          borderColor: "divider",
          zIndex: 1200,
        }}
      >
        {isDefined(placementDetails) && isDefined(placementCandidate) && (
          <Stack spacing={5}>
            <PlacementPrimaryAction
              isProspectWorkplace={Boolean(
                placementDetails.workplace.placementSettings?.isProspect
              )}
              primaryActionProps={primaryActionProps}
              isProfilePending={isProfilePending}
              requirements={requirements}
            />
            {bookedInterview && !applicationCompleted && (
              <InterviewCancelButton
                isProspectWorkplace={Boolean(
                  placementDetails.workplace.placementSettings?.isProspect
                )}
                disabled={false}
                onClick={() => {
                  cancelInterviewModalState.openModal();
                }}
              />
            )}
          </Stack>
        )}
      </Box>

      {placementApplicationSuccessModalState.modalIsOpen && (
        <PlacementApplicationSuccessBottomSheet
          modalState={placementApplicationSuccessModalState}
          placementDetails={placementDetails}
        />
      )}

      {walkInNoteModalState.modalIsOpen && (
        <WalkInNoteDialog
          modalState={walkInNoteModalState}
          workerType={placementDetails.workerTypes?.join(", ")}
          onContinue={() => {
            walkInNoteModalState.closeModal();
            bookInterviewModalState.openModal();
          }}
        />
      )}

      {bookInterviewModalState.modalIsOpen && (
        <PlacementDetailBookInterviewDialog
          isProspectWorkplace={Boolean(placementDetails.workplace.placementSettings?.isProspect)}
          placementActionType={bookInterviewModalType}
          modalState={bookInterviewModalState}
          interviewAvailability={interviewAvailability}
          onBookInterview={async (time) => {
            setSelectedInterviewTime(time);
            bookInterviewModalState.closeModal();
            const success = await (bookInterviewModalType === PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW
              ? handleBookInterview(time)
              : handleRescheduleInterview(time));
            await Promise.all([refetchPlacement(), refetchInterviewAvailability()]);
            // If the interview was booked successfully, show the interview booked modal
            if (success) {
              interviewBookedModalState.openModal();
            }
          }}
        />
      )}

      {interviewBookedModalState.modalIsOpen && isDefined(selectedInterviewTime) && (
        <InterviewBookedDialog
          modalState={interviewBookedModalState}
          placementDetails={placementDetails}
          selectedInterviewTime={selectedInterviewTime}
        />
      )}

      {cancelInterviewModalState.modalIsOpen && isDefined(interview?.id) && (
        <InterviewCancelBottomSheet
          isProspectWorkplace={Boolean(placementDetails.workplace.placementSettings?.isProspect)}
          modalState={cancelInterviewModalState}
          interviewId={interview.id}
          onCancelInterview={async () => {
            await Promise.all([refetchInterviewAvailability(), refetchPlacement()]);
          }}
        />
      )}
    </>
  );
}
