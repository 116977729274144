import { Blur, CbhIcon, LoadingSpinner, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useInView } from "react-intersection-observer";

import { JobType, ShiftNameType } from "../../PlacementCandidate/types";
import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";
import { type PayRate } from "../types/payRate";
import { formatPayRange, hasAppliedToPlacement } from "../utils";

interface PlacementCardPayProps {
  placement: PlacementWithDetails;
  placementCandidateId: string;
  payRate: PayRate | undefined;
  label: string | undefined;
  loadingPayData: boolean;
  shiftTypeFilter: ShiftNameType[] | undefined;
  jobTypeFilter: JobType[] | undefined;
  onClick: () => void;
}

export function PlacementCardPay(props: PlacementCardPayProps) {
  const {
    payRate,
    label,
    loadingPayData,
    placement,
    placementCandidateId,
    onClick,
    shiftTypeFilter,
    jobTypeFilter,
  } = props;

  const { userId: workerId } = useDefinedWorker();
  const payRateExperiment = usePayTransparencyFlag();
  const isBlurred = payRate?.externallyDerived && payRate?.obscured;
  const rateIsVisible = (payRate?.exact ?? payRate?.max ?? payRate?.min ?? 0) > 0;

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0,
    onChange: (inView) => {
      if (!inView) {
        return;
      }

      const hasApplied = hasAppliedToPlacement(placement);
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_PAY_VIEWED, {
        workerId,
        placementCandidateId,
        workplaceId: placement.workplace.id,
        placementId: placement.id,
        hasApplied,
        isBlurred,
        payRateExperiment: payRateExperiment.enabled,
        payRate,
      });
    },
  });

  const showExternalPayRate = payRateExperiment.enabled && payRate?.externallyDerived && !isBlurred;

  return (
    <Stack
      direction="column"
      spacing={1}
      onClick={(event) => {
        if (payRateExperiment.enabled && payRate?.externallyDerived) {
          event.preventDefault();
          onClick();
        }
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="nowrap"
        flexShrink={0}
        spacing={2}
      >
        {loadingPayData ? (
          <LoadingSpinner size="small" />
        ) : (
          <>
            {label && (
              <Stack ref={ref}>
                <Text
                  variant="subtitle2"
                  color={(theme) =>
                    showExternalPayRate
                      ? theme.palette.intent?.success.text
                      : theme.palette.intent?.disabled.text
                  }
                >
                  {label}
                </Text>
              </Stack>
            )}
            {payRate && rateIsVisible && (
              <Stack direction="row" alignItems="baseline">
                <Text
                  semibold
                  variant="h5"
                  color={(theme) =>
                    showExternalPayRate ? theme.palette.intent?.success.text : undefined
                  }
                >
                  {formatPayRange({
                    payRate,
                    showPerHour: false,
                    differentialTraits: {
                      shiftTypes: shiftTypeFilter ?? [ShiftNameType.AM],
                      withBenefits: true,
                      isWeekendShift: jobTypeFilter?.includes(JobType.WEEKEND_WARRIOR) ?? false,
                    },
                  })}
                </Text>

                <Text
                  variant="body2"
                  color={(theme) =>
                    showExternalPayRate ? theme.palette.intent?.success.text : undefined
                  }
                >
                  &nbsp;/hr
                </Text>
              </Stack>
            )}

            {isBlurred && (
              <Stack direction="row" alignItems="center">
                <Text semibold variant="h5">
                  $
                </Text>
                <Blur sx={{ width: "80px", height: "1.4rem" }} />
              </Stack>
            )}
          </>
        )}
      </Stack>
      {showExternalPayRate ? (
        <Pill
          label={
            <Stack direction="row" spacing={1} alignItems="center">
              <Text variant="body2">Calculate your pay rate</Text>
              <CbhIcon size="small" type="chevron-right" />
            </Stack>
          }
          color={(theme) => theme.palette.intent?.success.text}
          backgroundColor={(theme) => theme.palette.intent?.success.background}
        />
      ) : undefined}
    </Stack>
  );
}
